import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { ModalFileComponent } from 'src/app/components/modal-file/modal-file.component';
import { SelectInputComponent } from 'src/app/components/select-input/select-input.component';
import { AnalysesService } from 'src/app/services/analyses.service';
import { ArquivosService } from 'src/app/services/arquivos.service';
import { CompanyService } from 'src/app/services/company.service';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { verifyEnterpriseCollectorIsCorrect } from '../../../utils/new-analyses-utils';

interface Address {
  state_identifier: string;
  stateName: string;
  city: string;
  district: string;
  street: string;
  number: string;
  zipCode: string;
}

interface Place {
  nickname: string;
  type: string;
  code?: string;
  latitude?: string;
  longitude?: string;
}

interface Outsourced {
  document: string;
  address: Address;
  places: Place[];
  outsourced: Outsourced[];
  corporateName: string;
}

interface ProviderCompany {
  document: string;
  address: Address;
  places: Place[];
  outsourced: Outsourced[];
  corporateName: string;
  enterpriseCode: string;
}

interface Feature {
  name: string;
  value: string;
  type: string;
  usage: string;
}

interface Product {
  ean: string;
  plu: string;
  code: string;
  name: string;
}

interface ActionPlan {
  status: string;
}

interface Evidences {
  [key: string]: string | null;
}

interface Properties {
  [key: string]: string | null;
}

interface Laboratory {
  document: string | undefined;
  corporateName: string;
  outsourced: []
}

interface Data {
  providerCompany: ProviderCompany;
  collectionDate: string;
  product: Product;
  productBatch: string;
  trackingCode: string;
  result: string;
  status: string;
  type: string | null;
  year: number;
  actionPlan: ActionPlan;
  evidences: Evidences;
  laboratory: Laboratory;
  requester: string;
  properties: Properties
}

@Component({
  selector: 'app-new-analyses',
  templateUrl: './new-analyses.component.html',
  styleUrls: ['./new-analyses.component.scss']
})
export class NewAnalysesComponent implements OnInit {

  document: string = '';
  providerNotFound: boolean = false;
  formNewAnalyses: FormGroup;
  datasEnterprise: any = [];
  error: boolean = false;
  products: any = [];
  ean: any;
  plu: any;
  code: any;
  laboratorios: any;
  companies: any = [];
  collectionsCompany: any[] = [];
  enterprises: any;
  enterpriseSelected: any;
  collectors: any;
  dialogRef!: MatDialogRef<ModalConfirmComponent>;
  loading: boolean = false;
  street: string = "";
  city: string = "";
  number: string = "";
  district: string = "";
  zip: string = "";
  state: string = "";
  codeProvider: string = "";
  brand: string = "";
  isEditing: boolean = false;

  analyseSelected: any;
  idAnalyse: any = null;
  enterpriseOfAnalyse: any;

  archiveDocument: Array<{ name: string; path: string }> = [];
  archiveDocumentProvider: Array<{ name: string; path: string }> = [];

  optionsResultResidue = [
    'Satisfatório',
    'Insatisfatório',
    'Insatisfatório/Reincidente'
  ];

  actionPlan = [
    'Aguardando resposta',
    'Não aplica',
    'Concluído'
  ];

  providerOptionsList: Array<{ name: string, value: string }> = [];
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  providersList: any;

  private sessionStorage!: Storage;
  protected isUserProvider: boolean = false;
  protected showButtonDelete: boolean = false;

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private analysesService: AnalysesService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private fornecedoresService: FornecedoresService,
    private arquivosService: ArquivosService,
  ) {

    this.sessionStorage = window.sessionStorage;
    this.formNewAnalyses = new FormGroup({
      cnpj: new FormControl('', Validators.required),
      nomeFornecedor: new FormControl(''),
      produto: new FormControl('', Validators.required),
      localColeta: new FormControl(''),
      laboratorio: new FormControl('', Validators.required),
      dataColeta: new FormControl('', Validators.required),
      dataEntradaEmbalamento: new FormControl('', Validators.required),
      codigo: new FormControl(''),
      ean: new FormControl(''),
      plu: new FormControl(''),
      loteProduto: new FormControl('', Validators.required),
      codigoRastreio: new FormControl('', Validators.required),
      relatorioEnsaio: new FormControl('', Validators.required),
      status: new FormControl(''),
      planoAcao: new FormControl('', Validators.required),
      obs: new FormControl(''),
      empresaColeta: new FormControl(''),
      solicitante: new FormControl(''),
      lmr: new FormControl(false),
      nr: new FormControl(false),
      na: new FormControl(false),
      residueResult: new FormControl(''),
      codigoFornecedor: new FormControl(''),
      typeAnalyses: new FormControl(''),
      documentPath: new FormControl(''),
      documentProvider: new FormControl(''),
    });
  }

  async sendDatas() {
    const dateNow = new Date();
    this.formNewAnalyses.markAllAsTouched();
    if (this.formNewAnalyses.valid) {
      const dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {},
      });
      dialogref.afterClosed().subscribe(result => {
        if (result && result.confirm) {
          let placeSwitched: any;
          this.datasEnterprise[0].places.forEach((place: any) => {
            if (place.nickname == this.formNewAnalyses.get("localColeta")?.value) {
              placeSwitched = place;
            }
          });

          let documentLaboratory;
          this.laboratorios.forEach((laboratory: any) => {
            if (laboratory.corporateName == this.formNewAnalyses.get('laboratorio')?.value) {
              documentLaboratory = laboratory.document;
            }
          })

          let documentProvider = this.formNewAnalyses.get('cnpj')?.value;
          documentProvider = documentProvider.replace(/\D/g, '');

          const providerCompany = {
            document: documentProvider,
            corporateName: this.datasEnterprise[0].corporateName,
            outsourced: [],
            enterpriseCode: this.datasEnterprise[0].enterpriseCode
          };


          // const product: Product = {
          //   ean: this.formNewAnalyses.get('ean')?.value,
          //   plu: this.formNewAnalyses.get('plu')?.value,
          //   code: this.formNewAnalyses.get('codigo')?.value,
          //   name: this.formNewAnalyses.get('produto')?.value,
          // };

          const filteredProduct = this.products.filter((item: any) => this.removeSpaces(item.name) === this.removeSpaces(this.formNewAnalyses.get('produto')?.value));
          const product = filteredProduct[0];

          const actionPlan: ActionPlan = {
            status: this.formNewAnalyses.get('planoAcao')?.value.toLowerCase(),
          };

          const laboratory: Laboratory = {
            document: documentLaboratory,
            corporateName: this.formNewAnalyses.get('laboratorio')?.value,
            outsourced: [],
          };

          const properties: Properties = {
            nr: this.formNewAnalyses.get('nr')?.value,
            assayReportNumber: this.formNewAnalyses.get('relatorioEnsaio')?.value,
            lmr: this.formNewAnalyses.get('lmr')?.value,
            na: this.formNewAnalyses.get('na')?.value,
            observations: this.formNewAnalyses.get('obs')?.value,
            exclusiveBrand: this.brand,
            receivementDate: this.formNewAnalyses.get('dataEntradaEmbalamento')?.value
          };

          let collectorSwitched: any;

          this.collectors.forEach((collector: any) => {
            if (collector.corporateName == this.formNewAnalyses.get('empresaColeta')?.value) {
              collectorSwitched = collector;
            }
          });


          const year = new Date(this.formNewAnalyses.get('dataColeta')?.value).getFullYear();

          if (this.isEditing) {
            const collectingCompany = {
              enterpriseCode: collectorSwitched?.enterpriseCode,
              document: collectorSwitched?.document,
              corporateName: this.formNewAnalyses.get('empresaColeta')?.value,
            }
            const collectionCompany = {
              enterpriseCode: this.analyseSelected.collectionCompany?.enterpriseCode,
              document: this.analyseSelected.collectionCompany?.document,
              outsourced: [],
              corporateName: this.analyseSelected.collectionCompany?.corporateName
            }
            const AnalyseId = this.activeRouter.snapshot.queryParamMap.get('AnalyseId');
            const paths = [];
            paths.push(this.formNewAnalyses.get('documentPath')?.value);
            const jsonData = {
              id: AnalyseId,
              providerCompany: providerCompany,
              collectionCompany: collectionCompany,
              collectingCompany: collectingCompany,
              collectionDate: this.formNewAnalyses.get('dataColeta')?.value,
              product: product,
              productBatch: this.formNewAnalyses.get('loteProduto')?.value,
              trackingCode: this.formNewAnalyses.get('codigoRastreio')?.value,
              residueResult: this.formNewAnalyses.get('residueResult')?.value.toUpperCase(),
              //result: this.formNewAnalyses.get('resultado')?.value,
              status: this.formNewAnalyses.get('status')?.value.toUpperCase(),
              type: this.formNewAnalyses.get('typeAnalyses')?.value,
              year: year,
              actionPlan: {
                date: dateNow.toLocaleDateString(),
                name: "Plano de Ação",
                paths:  paths,
                status:  this.formNewAnalyses.get('planoAcao')?.value.toLowerCase(),
              },
              laboratory: laboratory,
              requester: this.formNewAnalyses.get('solicitante')?.value,
              properties: properties,
            };
            this.loading = true;
            this.analysesService.editNewAnalyses(jsonData).subscribe(
              async (data) => {
                this.loading = false;
                let response: any;
                response = data;
                if (response.id) {
                  await this.openModal();
                }
                else {
                  this.openModalError();
                }
              },
              (error) => {
                this.loading = false;
                this.openModalError();
              }
            );
          } else {
            const collectingCompany = {
              enterpriseCode: collectorSwitched?.enterpriseCode,
              document: collectorSwitched?.document,
              corporateName: this.formNewAnalyses.get('empresaColeta')?.value,
            }
            const collectionCompany = {
              enterpriseCode: this.enterpriseSelected[0]?.enterpriseCode,
              document: this.enterpriseSelected[0]?.document,
              outsourced: [],
              corporateName: this.enterpriseSelected[0]?.corporateName
            }
            const jsonData = {
              providerCompany: providerCompany,
              collectionCompany: collectionCompany,
              collectingCompany: collectingCompany,
              collectionDate: this.formNewAnalyses.get('dataColeta')?.value,
              product: product,
              productBatch: this.formNewAnalyses.get('loteProduto')?.value,
              trackingCode: this.formNewAnalyses.get('codigoRastreio')?.value,
              residueResult: this.formNewAnalyses.get('residueResult')?.value.toLowerCase(),
              //result: this.formNewAnalyses.get('resultado')?.value.toLowerCase(),
              type: localStorage.getItem('typeAnalyses'),
              year: year,
              actionPlan: {
                date: dateNow.toLocaleDateString(),
                name: "Plano de Ação",
                paths:  [this.formNewAnalyses.get('documentPath')?.value],
                status:  this.formNewAnalyses.get('planoAcao')?.value.toLowerCase(),
              },
              laboratory: laboratory,
              requester: this.formNewAnalyses.get('solicitante')?.value,
              properties: properties,
            };
            this.loading = true;
            this.analysesService.createNewAnalyses(jsonData).subscribe(
              async (data) => {
                this.loading = false;
                let response: any;
                response = data;
                if (response.id) {
                  await this.openModal();
                }
                else {
                  this.openModalError();
                }
              },
              (error) => {
                this.loading = false;
                this.openModalError();
              }
            );
          }
        }
      })
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const operationalType = this.activeRouter.snapshot.queryParamMap.get('operationalType');
    const typeAnalyses = this.activeRouter.snapshot.queryParamMap.get('typeAnalyses');
    const customer = this.activeRouter.snapshot.queryParamMap.get('customer');
    this.idAnalyse = this.activeRouter.snapshot.queryParamMap.get('AnalyseId');

    const item = this.sessionStorage.getItem('user');
    const user = item ? JSON.parse(item) : {};

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      this.isUserProvider = true;

      this.formNewAnalyses.get("produto")?.disable();
      this.formNewAnalyses.get("laboratorio")?.disable();
      this.formNewAnalyses.get("empresaColeta")?.disable();
      this.formNewAnalyses.get("residueResult")?.disable();
      this.formNewAnalyses.get("planoAcao")?.disable();
      this.formNewAnalyses.get("lmr")?.disable();
      this.formNewAnalyses.get("nr")?.disable();
      this.formNewAnalyses.get("na")?.disable();
    }

    if (this.idAnalyse) {
      this.isEditing = true;
      this.loading = true;
      this.analysesService.getAnalyseByID(this.idAnalyse).subscribe({
        next: res => {
          this.analyseSelected = res;
          this.getEnterpriseOfAnalyse();
          this.loading = false;
        }, error:  () => {
          this.loading = false;
        }
      })
    }


    let cdLoja = localStorage.getItem('cdLoja');
    if (cdLoja) {
      this.loading = true;
      this.companyService.getEnterpriseByDocument(cdLoja).subscribe({
        next: r => {
          this.enterpriseSelected = r;
          this.loading = false;
        }, error:  () => {
          this.loading = false;
        }
      })
    }

    this.loading = true;
    this.companyService.getEnterpriseByOperationType('COLLECTOR').subscribe(r => {
      this.collectors = r;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  async getEnterpriseOfAnalyse() {
    this.loading = true;
    this.companyService.getEnterpriseByDocument(this.analyseSelected.collectionCompany.document).subscribe(async r => {

      this.enterpriseOfAnalyse = r;
      this.enterpriseOfAnalyse = this.enterpriseOfAnalyse[0]?.id;
      this.initForms();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  async initForms() {
    let residueResult, actionPlan;
    if (this.analyseSelected?.residueResult.toLowerCase().trim() === 'satisfatório') {
      residueResult = 'Satisfatório';
    }
    else if (this.analyseSelected?.residueResult.toLowerCase().trim() == 'insatisfatório') {
      residueResult = 'Insatisfatório';
    }
    else if (this.analyseSelected?.residueResult.toLowerCase().trim() == 'insatisfatório/reincidente') {
      residueResult = 'Insatisfatório/Reincidente';
    }

    if (this.analyseSelected.actionPlan.status.toLowerCase().trim() == 'aguardando resposta') {
      actionPlan = 'Aguardando resposta'
    } else if (this.analyseSelected.actionPlan.status.toLowerCase().replace('não se aplica', 'não aplica').trim() == 'não aplica') {
      actionPlan = 'Não aplica'
    } else if (this.analyseSelected.actionPlan.status.toLowerCase().trim() == 'concluído') {
      actionPlan = 'Concluído'
    }
    this.brand = this.analyseSelected.properties?.exclusiveBrand;
    this.formNewAnalyses.get('cnpj')?.setValue(this.analyseSelected.providerCompany?.document);
    this.formNewAnalyses.get('nomeFornecedor')?.setValue(this.analyseSelected.providerCompany?.corporateName);
    this.inputProvider.value = this.analyseSelected.providerCompany?.corporateName;
    // ! chamada da req que vai preencher os campos d eprodutos e laboratorio
    this.findProviderByDocument(this.analyseSelected.providerCompany?.document);

    this.formNewAnalyses.get('produto')?.setValue(this.analyseSelected.product.name.replace(/\s/g, ''));
    this.formNewAnalyses.get('localColeta')?.setValue(this.analyseSelected.collectionCompany?.corporateName);
    this.formNewAnalyses.get('laboratorio')?.setValue(this.analyseSelected.laboratory?.corporateName);
    this.formNewAnalyses.get('dataColeta')?.setValue(this.analyseSelected.collectionDate);
    this.formNewAnalyses.get('dataEntradaEmbalamento')?.setValue(this.analyseSelected.properties?.receivementDate);
    this.formNewAnalyses.get('codigo')?.setValue(this.analyseSelected.product?.code);
    this.formNewAnalyses.get('ean')?.setValue(this.analyseSelected.product?.ean);
    this.formNewAnalyses.get('plu')?.setValue(this.analyseSelected.product?.plu);
    this.formNewAnalyses.get('notaFiscalAdm')?.setValue(this.analyseSelected.properties?.invoiceADM);
    this.formNewAnalyses.get('responsavelColeta')?.setValue(this.analyseSelected?.properties?.responsibleCollection);
    this.formNewAnalyses.get('loteProduto')?.setValue(this.analyseSelected.productBatch);
    this.formNewAnalyses.get('codigoRastreio')?.setValue(this.analyseSelected.trackingCode);
    this.formNewAnalyses.get('residueResult')?.setValue(residueResult);
    this.formNewAnalyses.get('obs')?.setValue(this.analyseSelected?.properties?.observations);
    this.formNewAnalyses.get('nr')?.setValue(this.analyseSelected.properties?.nr);
    this.formNewAnalyses.get('na')?.setValue(this.analyseSelected.properties?.na);
    this.formNewAnalyses.get('lmr')?.setValue(this.analyseSelected.properties?.lmr);
    this.formNewAnalyses.get('placaVeiculo')?.setValue(this.analyseSelected.properties?.licensePlate);
    this.formNewAnalyses.get('relatorioEnsaio')?.setValue(this.analyseSelected.properties?.assayReportNumber);
    this.formNewAnalyses.get('status')?.setValue(this.analyseSelected.status);
    this.formNewAnalyses.get('planoAcao')?.setValue(actionPlan);

    this.formNewAnalyses.get('documentPath')?.setValue(this.analyseSelected.actionPlan && this.analyseSelected.actionPlan?.paths == undefined ? '' : this.analyseSelected.actionPlan?.paths[0] ? this.analyseSelected.actionPlan?.paths[0] : '');
    // this.formNewAnalyses.get('planoAcao')?.setValue(actionPlan);
    if (this.analyseSelected.actionPlan) {
      const fileName = atob(this.formNewAnalyses.get('documentPath')?.value);
      this.archiveDocument = [
        {
          name: fileName.slice(7),
          path: this.formNewAnalyses.get('documentPath')?.value,
        }
      ];
    }

    this.formNewAnalyses.get('acoes')?.setValue(this.analyseSelected.properties?.actions);

    this.formNewAnalyses.get('empresaColeta')?.setValue(this.analyseSelected.collectingCompany.corporateName);

    this.formNewAnalyses.get('solicitante')?.setValue(this.analyseSelected.requester);
    this.formNewAnalyses.get('typeAnalyses')?.setValue(this.analyseSelected.type);
    this.formNewAnalyses.get('local')?.setValue(this.analyseSelected.collectionCompany.operationalType === 'STORE' ? 'Loja' : 'Centro de distribuição');
    // TODO: Remover essa condição quando a api estiver retornando o collectingCompany correto
    // * a reponse esta vindo com uma empresa que não existe no sistema, desse modo ele não vincula a nenhuma empresa presente no dropdown e envia como null (document).
    if (this.collectors && !verifyEnterpriseCollectorIsCorrect(this.analyseSelected.collectingCompany.document, this.collectors)) {
      this.formNewAnalyses.get('empresaColeta')?.setValue(this.collectors[0].corporateName);
    }
  }

  async changeInputResidue() {
    if (this.formNewAnalyses.get('lmr')?.value == false && this.formNewAnalyses.get('nr')?.value == false && this.formNewAnalyses.get('na')?.value == false) {
      this.optionsResultResidue = ['Insatisfatório', 'Satisfatório', 'Insatisfatório/Reincidente'];
    }
    else {
      this.optionsResultResidue = ['Insatisfatório', 'Insatisfatório/Reincidente']
    }

    await this.getStatus();
  }

  formatDocument() {
    let value = this.formNewAnalyses.get('cnpj')?.value.replace(/\D/g, '');
    if (value.length == 11) {
      this.findProviderByDocument(value);
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value.length > 11) {
      this.findProviderByDocument(value);
      value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    this.formNewAnalyses.get('cnpj')?.setValue(value);
  }

  removeSpaces(info: string): string {
    return info.replace(/\s/g, '');
  }

  deleteFileDocument(type: string) {
    if (type === 'gpa') {
      this.archiveDocument = [];
      this.formNewAnalyses.get('documentPath')?.setValue('');
    }
    if (type === 'provider') {
      this.archiveDocumentProvider = [];
      this.formNewAnalyses.get('documentProvider')?.setValue('');
    }
  }

  downloadFile(type: string) {
    let file = '';
    let fileName = '';
    if (type === 'gpa') {
      file = this.formNewAnalyses.get('documentPath')?.value;
      fileName = atob(file);
    }

    if (type === 'provider') {
      file = this.formNewAnalyses.get('documentProvider')?.value;
      fileName = atob(file);
    }
    if (file) {
      this.arquivosService.getFile(file).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.slice(7);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, error => {
      });
    }
  }

  openModalUploadDocument(type: string) {
    const dialogref = this.dialog.open(ModalFileComponent, {
      data: {
        selectFile: true,
        title: 'Carregar Documento'
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (result.file) {
          this.loading = true;
          this.arquivosService.uploadFile(result.file).subscribe(res => {
            this.loading = false;
            if (res) {
              this.showButtonDelete = true;
              this.formNewAnalyses.get('documentPath')?.setValue(res);
            }
          }, error => {
            this.loading = false;
            const hash64 = error.error.text;
            if (hash64) {
              this.showButtonDelete = true;
              if (type === 'gpa') {
                this.formNewAnalyses.get('documentPath')?.setValue(hash64);
                const fileName = atob(hash64);
                this.archiveDocument = [
                  {
                    name: fileName.slice(7),
                    path: hash64,
                  }
                ];
              }
              if (type === 'provider') {
                this.formNewAnalyses.get('documentProvider')?.setValue(hash64);
                const fileName = atob(hash64);
                this.archiveDocumentProvider = [
                  {
                    name: fileName.slice(7),
                    path: hash64,
                  }
                ];
              }
            }
          });
        }
      }
    });
  }

  getLocais() {
    let codigo = localStorage.getItem('codigo');
    this.companyService.getEnterprises().subscribe((data) => {

      this.companies = data;

      this.companies.forEach((company: any) => {
        if (company.enterpriseCode == codigo) {
          this.collectionsCompany.push(company);
        }
      })
    })
  }

  async getStatus() {
    if (this.formNewAnalyses.get('residueResult')?.value == 'Satisfatório') {
      this.formNewAnalyses.get('planoAcao')?.setValue('Não aplica')
      this.formNewAnalyses.get('status')?.setValue('Liberado');
      this.actionPlan = ['Não aplica'];
    }
    else if (this.formNewAnalyses.get('residueResult')?.value == 'Insatisfatório/Reincidente') {
      this.formNewAnalyses.get('planoAcao')?.setValue('Não aplica')
      this.formNewAnalyses.get('status')?.setValue('Suspenso');
      this.actionPlan = ['Não aplica'];
    }
    else if (this.formNewAnalyses.get('residueResult')?.value == 'Insatisfatório') {
      this.actionPlan = ['Concluído', 'Aguardando resposta', 'Não aplica'];
      this.formNewAnalyses.get('status')?.setValue('');
      if (this.formNewAnalyses.get('planoAcao')?.value == 'Aguardando resposta')
        this.formNewAnalyses.get('status')?.setValue('Em andamento');
      else if (this.formNewAnalyses.get('planoAcao')?.value == 'Concluído')
        this.formNewAnalyses.get('status')?.setValue('Liberado');
      else if (this.formNewAnalyses.get('planoAcao')?.value == 'Não aplica')
        this.formNewAnalyses.get('status')?.setValue('Suspenso');
    }
  }

  findProviderByName() {
    this.companyService.getEnterpriseByCorporateName(this.formNewAnalyses.get('nomeFornecedor')?.value).subscribe(
      (data) => {
        this.datasEnterprise = data;
        this.street = this.datasEnterprise[0].address.street;
        this.district = this.datasEnterprise[0].address.district;
        this.number = this.datasEnterprise[0].address.number;
        this.city = this.datasEnterprise[0].address.city;
        this.zip = this.datasEnterprise[0].address.zipCode;
        this.state = this.datasEnterprise[0].address.stateName;
        this.codeProvider = this.datasEnterprise[0].enterpriseCode;
        let document = this.datasEnterprise[0].document;


        if (document.length == 11) {
          this.findProviderByDocument(document);
          document = document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (document.length > 11) {
          this.findProviderByDocument(document);
          document = document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        this.formNewAnalyses.get('cnpj')?.setValue(document);
      },
      (error) => {
        this.providerNotFound = true;
      }
    )
  }

  findProviderByDocument(document: any) {
    if (this.formNewAnalyses.get('cnpj')?.value) {

      this.loading = true;
      this.companyService.getEnterpriseByDocument(document)
        .subscribe(
          (data) => {
            this.datasEnterprise = data;
            this.street = this.datasEnterprise[0].address.street;
            this.district = this.datasEnterprise[0].address.district;
            this.number = this.datasEnterprise[0].address.number;
            this.city = this.datasEnterprise[0].address.city;
            this.zip = this.datasEnterprise[0].address.zipCode;
            this.state = this.datasEnterprise[0].address.stateName;
            this.codeProvider = this.datasEnterprise[0].enterpriseCode;
            this.formNewAnalyses.get('nomeFornecedor')?.setValue(this.datasEnterprise[0].corporateName);
            this.inputProvider.value = this.datasEnterprise[0].corporateName;
            this.loading = false;
          },
          (error) => {
            this.error = true;
            this.providerNotFound = true;
            this.loading = false;
          }
        );

      const providerDocument = this.formNewAnalyses.get('cnpj')?.value.replace(/\D/g, '');
      this.loading = true;

      // ! Aqui onde pega os produtos para setar no dropdown
      this.analysesService.getProductByProviderCompany(providerDocument).subscribe(
        (products) => {
          this.products = products;
          this.setDatasProduct();
          this.loading = false;
        },
        (error) => {
          this.error = true;
          this.loading = false;
        }
      );

      this.loading = true;
      this.companyService.getEnterpriseLaboratory().subscribe({
        next: result => {
          this.loading = false;
          this.laboratorios = result;
        }, error: () => {
          this.loading = false;
        }
      });

    }
  }

  setDatasProduct() {
    this.products.forEach((product: any) => {
      if (this.formNewAnalyses.get('produto')?.value.replace(/\s/g, '') == product.name.replace(/\s/g, '')) {
        this.formNewAnalyses.get('ean')?.setValue(product.ean);
        this.formNewAnalyses.get('plu')?.setValue(product.plu);
        this.formNewAnalyses.get('codigo')?.setValue(product.code);

        product.features.forEach((feature: any) => {
          if (feature.name == 'me ou mr' || feature.name == 'exclusiveBrand') {
            this.brand = feature.value
          }
        });
      }
    });
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.uploadDocument(files);
  }

  uploadDocument(uploadFiles: any) {
    this.analysesService.uploadDocument(uploadFiles).subscribe(r => {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
          successText: 'Documento salvo com sucesso!',
        },
      });
    }, error => {
      if (error.status = 200) {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            success: true,
            successText: 'Documento salvo com sucesso!',
          },
        });
      }
      else {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: true,
            successText: 'Não foi possível salvar documento!',
            delete: true
          },
        });
      }
    })
  }

  async openModal() {
    let dialogref = this.dialog.open(ModalConfirmComponent, {
      data: {
        success: true,
        successText: 'Análise salva com sucesso!',
      },
    });


    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.redirect();
      }
    });

  }

  openModalError() {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        error: true,
        successText: 'Não foi possível salvar a análise!',
        delete: true
      },
    });
  }

  async redirect() {
    this.router.navigate(['/analises/flv']).then(() => {
      window.scrollTo(0, 0);
    });
  }

  changeProviderValue(event: any) {
    this.formNewAnalyses.get('cnpj')?.setValue(event);
    const document = this.formNewAnalyses.get('cnpj')?.value;
    const provider = this.providersList.filter((item: any) => item.document === document);
    this.formNewAnalyses.get('codigoFornecedor')?.setValue(provider[0].enterpriseCode);
    this.formNewAnalyses.get('nomeFornecedor')?.setValue(provider[0].corporateName);
    this.inputProvider.value = provider[0].corporateName;
    this.formNewAnalyses.get('cep')?.setValue(provider[0].address.zipCode);
    this.formNewAnalyses.get('rua')?.setValue(provider[0].address.street);
    this.formNewAnalyses.get('estado')?.setValue(provider[0].address.stateName);
    this.formNewAnalyses.get('numero')?.setValue(provider[0].address.number);
    this.formNewAnalyses.get('bairro')?.setValue(provider[0].address.stateName);
    this.formNewAnalyses.get('cidade')?.setValue(provider[0].address.city);
    this.findProviderByDocument(document);
  }

  getProviderSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      this.fornecedoresService.getProviderOutsourcedByName(event).subscribe((res: any) => {
        this.providersList = res.content;
        this.getProviderList();
        this.inputProvider.changeOptionsFilter(this.providerOptionsList);
        this.inputProvider.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }
  }

  public getProviderList(): void {
    this.loading = true;
    this.providerOptionsList = [];
    this.providerOptionsList = this.providersList.map((item: any) => {
      let option = {
        name: item.corporateName, value: item.document
      }
      return option;
    });
    this.inputProvider.changeOptionsFilter(this.providerOptionsList);
    this.loading = false;
  }
}

