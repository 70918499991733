<div class="form-group" [hidden]="isUserProvider" [ngStyle]="{ display: isUserProvider ? 'none' : '' }">
  <app-select-input
    #inputProvider
    label="Fornecedor"
    [required]="required"
    [disabled]="disabled"
    (changeValue)="changeProviderValue($event)"
    [list]="providerOptionsList"
    [loadMoreButton]="true"
    (loadMore)="loadMoreProviderOptionsList($event)"
    (getSearchOptions)="getProviderSearchOptions($event)"
  ></app-select-input>
</div>

<app-spinner *ngIf="loading"></app-spinner>
