import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAnalysesComponent } from './new-analyses.component';
import { NewAnalysesRoutingModule } from './new-analyses-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [
    NewAnalysesComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NewAnalysesRoutingModule,
    ComponentsModule
  ],
  exports: [
    NewAnalysesComponent
  ]
})

export class NewAnalysesModule { }
