<div class="content">
  <div class="back-button-area">
    <button type="button" [routerLink]="['/auditoria-social/' + auditType?.toLowerCase()]">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_460_4379)">
          <path d="M6.1998 2.875L1.9248 7.15L6.1998 11.425" stroke="#FFF" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M1.9248 7.1499H9.10258C14.0579 7.1499 18.0748 11.2097 18.0748 16.2181V17.1249" stroke="#FFF"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_460_4379">
            <rect width="19" height="19" transform="translate(0.5 0.5)" />
          </clipPath>
        </defs>
      </svg>
      <span>Voltar</span>
    </button>
  </div>

  <form class="form" [formGroup]="form" action="">
    <div class="header">
      <h4>Sustentabilidade - Inclusão de Auditoria Social Individual</h4>
    </div>

    <div class="header">
      <h4>Dados do Fornecedor</h4>
      <hr />
    </div>
    <div class="flex-area">
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="cnpj" name="cnpj" formControlName="cnpj" (change)="onChangeInput()" [readonly]="isUserProvider" />
        <label class="form-label" for="cnpj"
          [ngClass]="{ focus: editing || (form.get('cnpj')?.value && form.get('cnpj')?.value.length > 0) }">
          CNPJ/CPF
        </label>
        <span class="alert" *ngIf="
                form.get('cnpj')?.errors &&
                (form.get('cnpj')?.touched ||
                  form.get('cnpj')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="codeProvider" name="codeProvider" formControlName="codeProvider"
          (change)="onChangeCodeProvider()" [readonly]="isUserProvider" />
        <label class="form-label" for="codeProvider"
          [ngClass]="{ focus: editing || form.get('codeProvider')?.value && form.get('codeProvider')?.value.length > 0 }">
          {{ 'Código do Fornecedor' }}
        </label>
        <span class="alert" *ngIf="
                form.get('codeProvider')?.errors &&
                (form.get('codeProvider')?.touched ||
                  form.get('codeProvider')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group">
        <app-select-input #inputProvider label="Nome do Fornecedor" [required]="false"
          (changeValue)="changeProviderValue($event)" [list]="providerOptionsList"
          (getSearchOptions)="getProviderSearchOptions($event)" [disabled]="isUserProvider"></app-select-input>
      </div>
      <!-- <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="nameProvider" (change)="onChangeName()" name="nameProvider"
          formControlName="nameProvider" />
        <label class="form-label" for="nameProvider"
          [ngClass]="{ focus: editing || form.get('nameProvider')?.value && form.get('nameProvider')?.value.length > 0 }">
          {{ 'Nome do Fornecedor' }}
        </label>
        <span class="alert" *ngIf="
                form.get('nameProvider')?.errors &&
                (form.get('nameProvider')?.touched ||
                  form.get('nameProvider')?.dirty)
              ">Campo obrigatório</span>
      </div> -->
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="postalCode" name="postalCode" formControlName="postalCode"
          [readonly]="true" />
        <label class="form-label" for="postalCode"
          [ngClass]="{ focus: editing || form.get('postalCode')?.value && form.get('postalCode')?.value.length > 0 }">
          {{ 'CEP' }}
        </label>
        <span class="alert" *ngIf="
                form.get('postalCode')?.errors &&
                (form.get('postalCode')?.touched ||
                  form.get('postalCode')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="state" name="state" formControlName="state" [readonly]="true" />
        <label class="form-label" for="state"
          [ngClass]="{ focus: editing || form.get('state')?.value && form.get('state')?.value.length > 0 }">
          Estado
        </label>
        <span class="alert" *ngIf="
                form.get('state')?.errors &&
                (form.get('state')?.touched ||
                  form.get('state')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="city" name="city" formControlName="city" [readonly]="true" />
        <label class="form-label" for="city"
          [ngClass]="{ focus: editing || form.get('city')?.value && form.get('city')?.value.length > 0 }">
          {{ 'Cidade' }}
        </label>
        <span class="alert" *ngIf="
                form.get('city')?.errors &&
                (form.get('city')?.touched ||
                  form.get('city')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="street" name="street" formControlName="street" [readonly]="true" />
        <label class="form-label" for="street"
          [ngClass]="{ focus: editing || form.get('street')?.value && form.get('street')?.value.length > 0 }">
          Rua
        </label>
        <span class="alert" *ngIf="
                form.get('street')?.errors &&
                (form.get('street')?.touched ||
                  form.get('street')?.dirty)
              ">Campo obrigatório</span>
      </div>
      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="numberAddress" name="numberAddress" formControlName="numberAddress"
          [readonly]="true" />
        <label class="form-label" for="numberAddress"
          [ngClass]="{ focus: editing || form.get('numberAddress')?.value && form.get('numberAddress')?.value.length > 0 }">
          {{ 'Número' }}
        </label>
        <span class="alert" *ngIf="
                form.get('numberAddress')?.errors &&
                (form.get('numberAddress')?.touched ||
                  form.get('numberAddress')?.dirty)
              ">Campo obrigatório</span>
      </div>

      <div class="form-group" tabindex="-1">
        <input type="text" class="form-input" id="neighborhood" name="neighborhood" formControlName="neighborhood"
          [readonly]="true" />
        <label class="form-label" for="neighborhood"
          [ngClass]="{ focus: editing || form.get('neighborhood')?.value && form.get('neighborhood')?.value.length > 0 }">
          {{ 'Bairro' }}
        </label>
        <span class="alert" *ngIf="
                form.get('neighborhood')?.errors &&
                (form.get('neighborhood')?.touched ||
                  form.get('neighborhood')?.dirty)
              ">Campo obrigatório</span>
      </div>

      <div class="select-container" *ngIf="providerPlaces && providerPlaces.length > 0">
        <select class="input-search form-input" formControlName="auditedLocal" required (change)="onChangePlace()">
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let auditedLocal of providerPlaces" [value]="auditedLocal.hash">{{ auditedLocal.nickname }}
          </option>
        </select>

        <label style="color:#585858" class="form-label"
          [ngClass]="{ focus: editing || form.get('auditedLocal')?.value && form.get('auditedLocal')?.value.length > 0 }">
          Local Auditado
        </label>
        <span class="alert" *ngIf="
                  form.get('auditedLocal')?.errors &&
                  (form.get('auditedLocal')?.touched ||
                    form.get('auditedLocal')?.dirty)
                ">Campo obrigatório
        </span>
      </div>
    </div>

    <div class="header secondary">
      <h4>Informações de Sustentabilidade</h4>
      <hr />
    </div>

    <div class="flex-area">
      <div class="form-group" tabindex="-1">
        <input type="date" class="form-input" id="auditingDate" name="auditingDate" formControlName="auditingDate" [readonly]="isUserProvider" />
        <label class="form-label" for="auditingDate"
          [ngClass]="{ focus: editing || form.get('auditingDate')?.value && form.get('auditingDate')?.value.length > 0 }">
          Data da Auditoria
        </label>
        <span class="alert"
          *ngIf="form.get('auditingDate')?.errors && (form.get('auditingDate')?.touched || form.get('auditingDate')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <div class="select-container" tabindex="-1">
        <select class="form-input select" id="brand" name="brand" formControlName="brand">
          <option value="" disabled></option>
          <option [value]="false"> Regular </option>
          <option [value]="true">Exclusiva</option>
        </select>
        <label class="form-label" for="brand" [ngClass]="{ focus: true}">
          Marca
        </label>
        <span class="alert"
          *ngIf=" form.get('brand')?.errors && (form.get('brand')?.touched || form.get('brand')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <div class="form-group" tabindex="-1" style="width: 100px; min-width: inherit;">
        <input type="number" class="form-input" id="score" name="score" formControlName="score" max="100" min="0" [readonly]="isUserProvider" />
        <label class="form-label" for="score" [ngClass]="{ focus: editing || form.get('score')?.value }">
          Nota final
        </label>
        <span class="alert"
          *ngIf="form.get('score')?.errors && (form.get('score')?.touched || form.get('score')?.dirty)">
          Digite um valor de 0 a 100
        </span>
      </div>

      <div class="select-container">
        <select class="input-search form-input" formControlName="classification" required>
          <option value="" disabled></option>
          <option value="A"> A </option>
          <option value="B"> B </option>
          <option value="C"> C </option>
          <option value="D"> D </option>
          <option value="E"> E </option>
        </select>

        <label style="color:#585858" class="form-label"
          [ngClass]="{ focus: editing || form.get('classification')?.value }">
          Classificação
        </label>
        <span class="alert"
          *ngIf="form.get('classification')?.errors && (form.get('classification')?.touched || form.get('classification')?.dirty)">
          Campo obrigatório
        </span>
      </div>

      <div class="select-container">
        <select class="input-search form-input" formControlName="auditingCompany" (change)="selectAuditingCompany()"
          required>
          <option value="" [disabled] selected></option>
          <option *ngFor="let company of auditingCompanyList" [value]="company.document">{{company.corporateName}}
          </option>
        </select>

        <label style="color:#585858" class="form-label"
          [ngClass]="{ focus: editing || form.get('auditingCompany')?.value && form.get('auditingCompany')?.value.length > 0 }">
          Empresa de Auditoria
        </label>
        <span class="alert" *ngIf="
                    form.get('auditingCompany')?.errors &&
                    (form.get('auditingCompany')?.touched ||
                      form.get('auditingCompany')?.dirty)
                  ">Campo obrigatório
        </span>
      </div>

      <button type="button" style="margin-top:29px;margin-left:10px; width:50px" (click)="openModalProviders()"
        class="button save" [disabled]="isUserProvider" [ngStyle]="{ display: isUserProvider ? 'none' : '' }">
        +
      </button>

      <div class="tooltip-container" style="margin-top: 34px;margin-left: 2px;" [ngStyle]="{ display: isUserProvider ? 'none' : '' }">
        <span class="tooltip-trigger">?</span>
        <div class="tooltip">Cadastrar nova empresa auditora</div>
      </div>

      <div class="select-container">
        <select class="input-search form-input" formControlName="process" required>
          <option value="" disabled></option>
          <option *ngFor="let process of companyProcess" [value]="process.name">{{ process.name }}</option>
        </select>

        <label style="color:#585858" class="form-label"
          [ngClass]="{ focus: editing || form.get('process')?.value && form.get('process')?.value.length > 0 }">
          Processo
        </label>
        <span class="alert"
          *ngIf="form.get('process')?.errors && (form.get('process')?.touched || form.get('process')?.dirty)">
          Campo obrigatório
        </span>
      </div>
    </div>
    <div class="header secondary">
      <h4>Resultado da Sustentabilidade</h4>
      <hr />
    </div>

    <div class="flex-area">
      <div class="select-container" tabindex="-1">
        <select class="form-input select" id="result" name="result" formControlName="result">
          <option value="" disabled></option>
          <option value="APROVADO"> Aprovado</option>
          <option value="REPROVADO"> Reprovado</option>
        </select>
        <label class="form-label" for="result"
          [ngClass]="{ focus: form.get('result')?.value && form.get('result')?.value.length > 0 }">
          Resultado
        </label>
        <span class="alert"
          *ngIf="form.get('result')?.errors && (form.get('result')?.touched ||form.get('result')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <div class="select-container" tabindex="-1">
        <select class="form-input select" id="actionPlan" name="actionPlan" formControlName="actionPlan">
          <option value="" disabled></option>
          <option value="AGUARDANDO RESPOSTA">Aguardando Resposta</option>
          <option value="NÃO APLICA"> Não Aplica</option>
          <option value="CONCLUÍDO"> Concluído</option>
        </select>

        <label class="form-label" for="actionPlan"
          [ngClass]="{ focus: form.get('actionPlan')?.value && form.get('actionPlan')?.value.length > 0 }">
          Plano de ação
        </label>
        <span class="alert"
          *ngIf="form.get('actionPlan')?.errors &&(form.get('actionPlan')?.touched || form.get('actionPlan')?.dirty)">
          Campo obrigatório
        </span>
      </div>


      <div class="select-container" tabindex="-1">
        <select class="form-input select" id="status" name="status" formControlName="status">
          <option value="" disabled></option>
          <option value="LIBERADO">Liberado</option>
          <option value="SUSPENSO">Suspenso</option>
          <option value="EM ANDAMENTO">Em Andamento</option>
        </select>

        <label class="form-label" for="status"
          [ngClass]="{ focus: form.get('status')?.value && form.get('status')?.value.length > 0 }">
          Status
        </label>
        <span class="alert" *ngIf="
                    form.get('status')?.errors &&
                    (form.get('status')?.touched ||
                      form.get('status')?.dirty)
                  ">Campo obrigatório
        </span>
      </div>
    </div>

    <div class="buttons-area">
      <div class="send-file-buttons-area">
        <div class="file-send-area" *ngIf="editing || !isUserProvider">
          <button type="button" class="button upload-action-plan" (click)="openModalUploadDocument('gpa')" [disabled]="isUserProvider">
            <img src="../../../../assets/images/upload-icon.svg" alt="Icone upload" />
            <span>Carregar Documento</span>
          </button>
          <div *ngIf="archiveDocument.length > 0" class="file-area">
            <span>{{ archiveDocument[0].name }}</span>
            <button type="button" class="button btn-delete" (click)="deleteFileDocument('gpa')" [ngStyle]="{ display: isUserProvider && editing ? 'none' : '' }">
              <img src="../../../../assets/images/delete-icon.svg" alt="Icone de apagar" />
            </button>
            <button type="button" class="button btn-delete" (click)="downloadFile('document')" *ngIf="editing">
              <img src="../../../../assets/images/download-icon.svg" alt="Icone de download" />
            </button>
          </div>
        </div>
        <div class="file-send-area" *ngIf="editing || isUserProvider">
          <button type="button" class="button upload-action-plan" (click)="openModalUploadDocument('provider')"  [disabled]="!isUserProvider">
            <img src="../../../../assets/images/upload-icon.svg" alt="Icone upload" />
            <span>Carregar Documento Fornecedor</span>
          </button>
          <div *ngIf="archiveDocumentProvider.length > 0" class="file-area">
            <span>{{ archiveDocumentProvider[0].name }}</span>
            <button type="button" class="button btn-delete" (click)="deleteFileDocument('provider')" [ngStyle]="{ display: isUserProvider && editing ? 'none' : '' }">
              <img src="../../../../assets/images/delete-icon.svg" alt="Icone de apagar" />
            </button>
            <button type="button" class="button btn-delete" (click)="downloadFile('document-provider')" *ngIf="editing">
              <img src="../../../../assets/images/download-icon.svg" alt="Icone de download" />
            </button>
          </div>
        </div>
        <div class="file-send-area" *ngIf="editing || !isUserProvider">
          <button type="button" class="button upload-action-plan" (click)="openModalUploadActionPlan('gpa')" [disabled]="isUserProvider">
            <img src="../../../../assets/images/upload-icon.svg" alt="Icone upload" />
            <span>Carregar Plano de Ação</span>
          </button>
          <div *ngIf="archiveActionPlan.length > 0" class="file-area">
            <span>{{ archiveActionPlan[0].name }}</span>
            <button type="button" class="button btn-delete" (click)="deleteFileActionPlan('gpa')" [ngStyle]="{ display: isUserProvider && editing ? 'none' : '' }">
              <img src="../../../../assets/images/delete-icon.svg" alt="Icone de apagar" />
            </button>
            <button type="button" class="button btn-delete" (click)="downloadFile('action-plan')" *ngIf="editing">
              <img src="../../../../assets/images/download-icon.svg" alt="Icone de download" />
            </button>
          </div>
        </div>
        <div class="file-send-area" *ngIf="editing || isUserProvider">
          <button type="button" class="button upload-action-plan" (click)="openModalUploadActionPlan('provider')" [disabled]="!isUserProvider">
            <img src="../../../../assets/images/upload-icon.svg" alt="Icone upload" />
            <span>Carregar Plano de Ação Fornecedor</span>
          </button>
          <div *ngIf="archiveActionPlanProvider.length > 0" class="file-area">
            <span>{{ archiveActionPlanProvider[0].name }}</span>
            <button type="button" class="button btn-delete" (click)="deleteFileActionPlan('provider')" [ngStyle]="{ display: isUserProvider && editing ? 'none' : '' }">
              <img src="../../../../assets/images/delete-icon.svg" alt="Icone de apagar" />
            </button>
            <button type="button" class="button btn-delete" (click)="downloadFile('action-plan-provider')" *ngIf="editing">
              <img src="../../../../assets/images/download-icon.svg" alt="Icone de download" />
            </button>
          </div>
        </div>
      </div>
      <button type="button" class="button save" (click)="submitForm()">
        <img src="../../../../assets/images/save-icon.svg" alt="Icone de Salvar" />
        <span>Salvar</span>
      </button>
    </div>
  </form>
</div>

<app-spinner *ngIf="loading"> </app-spinner>
