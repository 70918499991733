import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { PostalCodeService } from 'src/app/services/postal-code.service';


@Component({
  selector: 'app-modal-providers',
  templateUrl: './modal-providers.component.html',
  styleUrls: ['./modal-providers.component.scss']
})
export class ModalProvidersComponent implements OnInit {
  private storage!: Storage;
  auditingCompanyList: Array<any> = [];
  protected openOptions: boolean = false;
  loading: boolean = false;
  enterpriseCreated: any;

  types: Array<{ name: string; value: string | number }> = [
    { name: 'Fazenda', value: 'FARM' },
    { name: 'Packing', value: 'STORE' },
    { name: 'Processador', value: 'PROCESSOR' },
    { name: 'Indústria', value: 'WAREHOUSE' },
    { name: 'Frigorífico', value: 'FRIDGE' },
    { name: 'Laboratório', value: 'LABORATORY' },
    { name: 'Auditor', value: 'AUDITING' },
    { name: 'Outros', value: 'OTHERS' },
  ];

  protected form: FormGroup = new FormGroup({
    providerName: new FormControl('', Validators.required),
    cnpj: new FormControl('', Validators.required),
    type: new FormControl(''),
    postalCode: new FormControl(''),
    street: new FormControl(''),
    number: new FormControl(''),
    neighborhood: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    enterpriseCode: new FormControl(null)
  });

  constructor(
    private dialogRef: MatDialogRef<ModalProvidersComponent>,
    private fornecedoresService: FornecedoresService,
    public dialog: MatDialog,
    private postalCodeService: PostalCodeService,
    @Inject(MAT_DIALOG_DATA) public dataModal: any
  ) { }

  ngOnInit(): void {
    if (this.dataModal.document) {
      this.form.get('cnpj')?.setValue(this.dataModal.document);
    }
  }

  public confirmModal(): void {

    if (!this.form.valid) {
      return
    }

    const data = this.form.get("company")?.value;
    this.dialogRef.close(data);
  }

  public onClickOpenOptions(): void {
    this.openOptions = true;
  }

  getProviders() {
    this.loading = true;
    this.fornecedoresService.getProviderOutsourcedByName(this.form.get('provider')?.value).subscribe((res: any) => {
      this.auditingCompanyList = res.content;
      this.openOptions = true;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('#input-provider') && this.openOptions) {
      this.openOptions = false;
    }
  }

  getValue(provider: any) {
    this.form.get('provider')?.setValue(provider.corporateName);
    this.form.get('documentProvider')?.setValue(provider.document);
    this.form.get('company')?.setValue(provider);
    this.openOptions = false;
  }

  openModalConfirm() {
    if (this.dataModal.auditing) {
      this.form.get('type')?.setValue('AUDITING');
    }

    this.form.markAllAsTouched();

    if (!this.form.invalid) {
      if (this.dataModal.enterpriseDocument === this.form.get('cnpj')?.value) {
        this.openModalSuccessOrError(true, 'O Documento cadastrado não pode ser o mesmo do fornecedor selecionado');
      } else {
        const dialogref = this.dialog.open(ModalConfirmComponent, {
          data: {
            fornecedor: this.form.value,
          },
        });
        dialogref.afterClosed().subscribe(result => {
          if (result.confirm) {
            this.loading = true;

            this.fornecedoresService.criarFornecedor(this.form.value, this.dataModal.customerIdProvider).subscribe(r => {
              this.enterpriseCreated = r;
              this.openModalSuccessOrError(false);
              this.loading = false;
            }, error => {
              this.openModalSuccessOrError(true);
              this.loading = false;
            })
          }
        }, error => {
          this.enterpriseCreated = null;
        });
      }
    }
  }

  openModalSuccessOrError(error = false, msg?: string) {
    this.loading = false;
    if (error) {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          error: true,
          errorText: msg ? msg : '',
        },
      });
    } else {
      this.dialog.open(ModalConfirmComponent, {
        data: {
          success: true,
        },
      }).afterClosed().subscribe(result => {
        this.closeModal();
      });
    }

    this.loading = false;
  }

  closeModal() {
    this.dialogRef.close(this.enterpriseCreated);
  }

  public onPostalCodeChange() {
    this.postalCodeService.getAddresByPostalCode(this.form.get('postalCode')?.value).subscribe(
      res => {
        this.form.get('street')?.setValue(res.logradouro);
        this.form.get('neighborhood')?.setValue(res.bairro);
        this.form.get('city')?.setValue(res.localidade);
        this.form.get('state')?.setValue(res.uf);
      }, error => {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: true,
            errorText: "Não foi possível encontrar o CEP informado!"
          },
        });
      }
    )
  }
}
