import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { SelectInputComponent } from 'src/app/components/select-input/select-input.component';
import { ModalProvidersComponent } from 'src/app/components/modal-providers/modal-providers.component';
import { AuditoriaService } from 'src/app/services/auditoria.service';
import { CompanyService } from 'src/app/services/company.service';
import { FornecedoresService } from 'src/app/services/fornecedores.service';
import { ProdutoService } from 'src/app/services/produto.service';
import formatCpfCnpj from 'src/app/utils/formatCpfCnpj';
import { ModalFileComponent } from 'src/app/components/modal-file/modal-file.component';

@Component({
  selector: 'app-form-social-audit',
  templateUrl: './form-social-audit.component.html',
  styleUrls: ['./form-social-audit.component.scss']
})
export class FormSocialAuditComponent implements OnInit {

  auditingCompanyList: Array<any> = [];
  auditedCompanyList: Array<any> = [];
  companyProcess: Array<any> = [];
  auditType: string | null = '';
  editing: boolean = false;
  loading: boolean = false;
  providerCompany: any;
  placesList: any;
  editAudit: any;
  auditingCompany: any;
  customerIdUserLogged: string = "";
  archiveActionPlan: Array<{ name: string; path: string }> = [];
  archiveActionPlanProvider: Array<{ name: string; path: string }> = [];
  archiveDocument: Array<{ name: string; path: string }> = [];
  archiveDocumentProvider: Array<{ name: string; path: string }> = [];

  uploadArchiveDocument: any;
  uploadArquiveActionPlan: any;

  providerOptionsList: Array<{ name: string, value: string }> = [];
  @ViewChild('inputProvider') inputProvider!: SelectInputComponent;
  providersList: any;

  providerPlaces: any = [];
  selectedPlace: any;

  private storage!: Storage;
  protected isUserProvider: boolean = false;
  protected showButtonDelete: boolean = false;

  form: FormGroup = this.fb.group({
    // Dados Fornecedor
    id: [''],
    cnpj: ['', Validators.required],
    codeProvider: ['', Validators.required],
    nameProvider: ['', Validators.required],
    postalCode: ['', Validators.required],
    street: ['', Validators.required],
    state: ['', Validators.required],
    numberAddress: ['', Validators.required],
    neighborhood: ['', Validators.required],
    city: ['', Validators.required],

    // Informações de Auditoria
    auditType: ['Social'],
    auditingDate: ['', Validators.required],
    brand: ['', Validators.required],
    process: [''],
    auditedCompany: null,
    auditor: null,
    auditingCompany: null,
    observation: null,
    cultures: null,
    auditedLocal: null,
    classification: ['', Validators.required],


    // Certificado
    qualityCertificate: null,
    describeCertificate: null,
    certificateValidity: null,

    // Resultado Auditoria
    score: ['', Validators.required],
    result: ['', Validators.required],
    actionPlan: ['', Validators.required],
    status: ['', Validators.required],


    pathArchiveActionPlan: [''],
    reportPaths: [''],
    reportFileName: [''],

    documentProvider: [''],
    actionPlanProvider: [''],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auditoriaService: AuditoriaService,
    private activeRouter: ActivatedRoute,
    private companyService: CompanyService,
    public dialog: MatDialog,
    private fornecedoresService: FornecedoresService,
  ) {
    this.storage = window.sessionStorage;
  }

  ngOnInit(): void {

    const item = this.storage.getItem('user');
    const user = item ? JSON.parse(item) : {};
    this.customerIdUserLogged = user.customerId;

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER")) {
      this.isUserProvider = true;

      this.form.get("auditedLocal")?.disable();
      this.form.get("brand")?.disable();
      this.form.get("classification")?.disable();
      this.form.get("auditingCompany")?.disable();
      this.form.get("process")?.disable();
      this.form.get("result")?.disable();
      this.form.get("actionPlan")?.disable();
      this.form.get("status")?.disable();
    }

    this.editing = false;
    const editId = this.activeRouter.snapshot.queryParamMap.get('id');
    this.auditType = this.activeRouter.snapshot.queryParamMap.get('type');
    if (editId) {
      this.auditoriaService.getAuditById(editId).subscribe(res => {
        if (res instanceof Array) {
          this.editAudit = res.filter(item => item.id === editId);
          this.editAudit = this.editAudit[0];
        }
        // Informações do Fornecedor
        this.form.get('cnpj')?.setValue(this.editAudit.providerCompany?.document);

        if (this.editAudit.providerCompany != undefined) {
          this.onChangeInput();
        }

        // Informações de Auditoria
        this.form.get('id')?.setValue(this.editAudit.id);
        this.form.get('auditType')?.setValue(this.editAudit.auditedCompany && this.editAudit.providerCompany?.document !== this.editAudit.auditedCompany.document ? 'terceiros' : 'propria');
        this.form.get('auditingDate')?.setValue(this.editAudit.auditingDate);
        this.form.get('brand')?.setValue(this.editAudit.exclusiveBrand);

        this.form.get('auditedCompany')?.setValue(this.editAudit.auditedCompany && this.editAudit.auditedCompany.document ? this.editAudit.auditedCompany.document : this.editAudit.providerCompany.document);
        this.form.get('process')?.setValue(this.editAudit.process ? this.editAudit.process.name : '');
        this.form.get('auditor')?.setValue(this.editAudit.auditor || '');
        this.form.get('auditingCompany')?.setValue(this.editAudit.auditingCompany ? this.editAudit.auditingCompany.document : '');

        //Resultado Auditoria
        this.form.get('score')?.setValue(this.editAudit.score);

        this.form.get('result')?.setValue(this.editAudit.result || '');

        if (this.editAudit.actionPlan != undefined) {
          this.editAudit.actionPlan.status = this.editAudit?.actionPlan?.status.toUpperCase();
          this.editAudit.actionPlan.status = this.editAudit?.actionPlan?.status.replace('Í', 'I');
          this.form.get('actionPlan')?.setValue(this.editAudit.actionPlan.status);
        }

        this.form.get('status')?.setValue(this.editAudit.status.toUpperCase());

        let actionPlan = this.getFeatureByName(this.editAudit, 'actionPlan');
        if (actionPlan) {
          this.form.get('actionPlan')?.setValue(actionPlan);
        }
        let classification = this.getFeatureByName(this.editAudit, 'classification');
        if (classification) {
          this.form.get('classification')?.setValue(classification);
        }
        let status = this.getFeatureByName(this.editAudit, 'status');
        if (status) {
          this.form.get('status')?.setValue(status);
        }

        let auditedLocal = this.getFeatureByName(this.editAudit, 'auditedLocal');
        if (auditedLocal) {
          this.form.get('auditedLocal')?.setValue(auditedLocal);
        }
        this.form.get('pathArchiveActionPlan')?.setValue(this.editAudit.actionPlan && this.editAudit.actionPlan?.paths == undefined ? '' : this.editAudit.actionPlan?.paths[0] ? this.editAudit.actionPlan?.paths[0] : '');
        this.form.get('reportPaths')?.setValue(this.editAudit.reportPaths && this.editAudit.reportPaths[0] ? this.editAudit.reportPaths[0] : '');
        this.form.get('reportFileName')?.setValue(this.editAudit.reportFileName ? this.editAudit.reportFileName : '');
        this.selectedPlace = this.editAudit.auditedCompany?.places[0];
        if (this.editAudit.actionPlan && this.editAudit.actionPlan.paths !== undefined) {
          if (this.editAudit.actionPlan.paths[0]) {
            this.archiveActionPlan = [
              {
                name: atob(this.editAudit.actionPlan.paths[0]).slice(7),
                path: this.editAudit.actionPlan.paths[0],
              }
            ];
          }
        }

        if (this.editAudit.reportPaths && this.editAudit.reportPaths[0]) {
          this.archiveDocument = [
            {
              name: atob(this.editAudit.reportPaths[0]).slice(7),
              path: this.editAudit.reportPaths[0],
            }
          ];
        }

        const documentProvider = this.getFeatureByName(this.editAudit, 'documentProvider');
        const actionPlanProvider = this.getFeatureByName(this.editAudit, 'actionPlanProvider');

        if (documentProvider && documentProvider !== '-') {
          this.archiveDocumentProvider = [
            {
              name: atob(documentProvider).slice(7),
              path: documentProvider,
            }
          ];
        }

        if (actionPlanProvider && actionPlanProvider !== '-') {
          this.archiveActionPlanProvider = [
            {
              name: atob(actionPlanProvider).slice(7),
              path: actionPlanProvider,
            }
          ];
        }

        this.form.get('result')?.setValue(this.editAudit.result || '');
        this.editing = true;
      });
    }
    this.getAuditedCompanies();
    this.getAuditingCompanies();
    this.getCompanyProcess();
  }

  getAuditedCompanies() {
    this.loading = true;
    this.companyService.getEnterprises().subscribe({
      next: (res: any) => {
        this.loading = false;
        this.auditedCompanyList = [];
        res.map((item: any) => this.auditedCompanyList.push(item));
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  getAuditingCompanies() {
    this.loading = true;
    this.companyService.getAuditingEnterprises().subscribe({
      next: (res: any) => {
        this.loading = false;
        this.auditingCompanyList = [];
        res.map((item: any) => this.auditingCompanyList.push(item));
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  getCompanyProcess() {
    this.loading = true;
    this.companyService.getEnterprisesProcess().subscribe({
      next: (res: any) => {
        this.loading = false;
        this.companyProcess = [];
        res.map((item: any) => this.companyProcess.push(item));
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  onChangeInput() {
    this.loading = true;
    const document: string = this.form.get('cnpj')?.value;
    const documentNumber: string = document.replace(/\D/g, '');
    this.form.get('cnpj')?.setValue(formatCpfCnpj(document));


    this.companyService.getEnterpriseByDocument(documentNumber).subscribe({
      next: (res: any) => {
        if (res.length > 0) {
          this.form.get('codeProvider')?.setValue(res[0].enterpriseCode);
          this.form.get('nameProvider')?.setValue(res[0].corporateName);
          this.inputProvider.value = res[0].corporateName;
          this.form.get('postalCode')?.setValue(res[0].address.zipCode);
          this.form.get('street')?.setValue(res[0].address.street);
          this.form.get('state')?.setValue(res[0].address.stateIdentifier);
          this.form.get('numberAddress')?.setValue(res[0].address.number);
          this.form.get('neighborhood')?.setValue(res[0].address.stateName);
          this.form.get('city')?.setValue(res[0].address.city);
          this.providerCompany = res;
          this.providerPlaces = res[0].places;
          this.placesList = this.providerCompany.places ? this.providerCompany.places : [];
        } else {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: 'true',
              errorText: 'Fornecedor não registrado'
            }
          });
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: 'true',
            errorText: 'Fornecedor não registrado'
          }
        });
      },
    });
  }

  onChangeCodeProvider() {
    this.loading = true;
    const provider = this.form.get('codeProvider')?.value;

    this.companyService.getEnterpriseByCode(provider).subscribe({
      next: (res: any) => {
        if (res.length > 0) {
          this.form.get('cnpj')?.setValue(res[0].document)
          this.form.get('codeProvider')?.setValue(res[0].enterpriseCode)
          this.form.get('nameProvider')?.setValue(res[0].corporateName);
          this.inputProvider.value = res[0].corporateName;
          this.form.get('postalCode')?.setValue(res[0].address.zipCode);
          this.form.get('street')?.setValue(res[0].address.street);
          this.form.get('state')?.setValue(res[0].address.stateIdentifier);
          this.form.get('numberAddress')?.setValue(res[0].address.number);
          this.form.get('neighborhood')?.setValue(res[0].address.stateName);
          this.form.get('city')?.setValue(res[0].address.city);
          this.providerCompany = res;
          this.providerPlaces = res[0].places;
          this.placesList = this.providerCompany.places ? this.providerCompany.places : [];
        } else {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: 'true',
              errorText: 'Fornecedor não registrado'
            }
          });
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: 'true',
            errorText: 'Fornecedor não registrado'
          }
        });
      },
    });
  }

  openModalProviders() {
    const datas = {
      auditing: true,
      customerIdProvider: this.customerIdUserLogged
    };

    const dialogRef = this.dialog.open(ModalProvidersComponent, {
      data: datas
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.document) {
        this.getAuditingCompanies();
      }
    });
  }

  onChangeName() {
    this.loading = true;
    const name = this.form.get('nameProvider')?.value;

    this.companyService.getEnterpriseByCorporateName(name).subscribe({
      next: (res: any) => {
        if (res.length > 0) {
          this.form.get('cnpj')?.setValue(res[0].document)
          this.form.get('codeProvider')?.setValue(res[0].id);
          this.form.get('postalCode')?.setValue(res[0].address.zipCode);
          this.form.get('street')?.setValue(res[0].address.street);
          this.form.get('state')?.setValue(res[0].address.stateIdentifier);
          this.form.get('numberAddress')?.setValue(res[0].address.number);
          this.form.get('neighborhood')?.setValue(res[0].address.stateName);
          this.form.get('city')?.setValue(res[0].address.city);
          this.providerCompany = res;
          this.providerPlaces = res[0].places;
          this.placesList = this.providerCompany.places ? this.providerCompany.places : [];
        } else {
          this.dialog.open(ModalConfirmComponent, {
            data: {
              error: 'true',
              errorText: 'Fornecedor não registrado'
            }
          });
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.dialog.open(ModalConfirmComponent, {
          data: {
            error: 'true',
            errorText: 'Fornecedor não registrado'
          }
        });
      },
    });
  }

  back() {
    this.router.navigate(['/auditoria-social']);
  }

  submitForm() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const dialogref = this.dialog.open(ModalConfirmComponent, {
        data: {}
      });
      dialogref.afterClosed().subscribe(result => {
        if (result && result.confirm) {
          this.loading = true;
          if (this.editing) {
            const process = this.companyProcess.find(item => item.name === this.form.get('process')?.value);
            const auditingCompany = this.auditingCompanyList.find(item => item.document === this.form.get('auditingCompany')?.value);
            this.auditoriaService.editarAuditoriaSocial(this.form.value, this.auditType || 'FLV', this.providerCompany[0], auditingCompany, this.selectedPlace, process).subscribe(res => {
              this.loading = false;
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                },
              }).afterClosed().subscribe(result => {
                this.back();
              });
            },
              error => {
                this.loading = false;
                this.dialog.open(ModalConfirmComponent, {
                  data: {
                    error: true,
                  },
                });
              });
          } else {
            const process = this.companyProcess.find(item => item.name = this.form.get('process')?.value);
            this.auditoriaService.adicionarAuditoriaSocial(this.form.value, this.auditType || 'FLV', this.selectedPlace, process).subscribe(res => {
              this.loading = false;
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                },
              }).afterClosed().subscribe(result => {
                this.back();
              });
            },
              error => {
                this.loading = false;
                this.dialog.open(ModalConfirmComponent, {
                  data: {
                    error: true,
                  },
                });
              });
          }
        }
      });
    }
  }

  selectAuditingCompany() {
    const auditingCompany = this.form.get('auditingCompany')?.value;
    this.auditingCompanyList.filter(item => {
      if (auditingCompany === item.document) {
        this.auditingCompany = item;
      }
    });
  }

  getFormArray(type: string) {
    return (<UntypedFormArray>this.form.get(type)).controls;
  }

  public removeIndexForm(index: number, type: string) {
    const formaArray = this.form.get(type) as UntypedFormArray;
    formaArray.removeAt(index);
  }

  onChangePlace() {
    const placeHash = this.form.get('auditedLocal')?.value;
    const selectedPlace = this.providerPlaces.filter((item: any) => item.hash === placeHash);
    this.selectedPlace = selectedPlace[0];
  }

  onChangeAuditedCompany() {
    const documentAuditedCompany = this.form.get('auditedCompany')?.value;
    const selectedAuditedCompany = this.providerCompany[0].outsourced.filter((item: any) => item.document === documentAuditedCompany);
    if (this.form.get('auditType')?.value === 'terceiros') {
      this.providerPlaces = selectedAuditedCompany[0].places;
    }
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      })
    }
    return returnFeature.value;
  }

  changeProviderValue(event: any) {
    this.form.get('cnpj')?.setValue(event);
    const document = this.form.get('cnpj')?.value;
    const provider = this.providersList.filter((item: any) => item.document === document);
    this.form.get('codeProvider')?.setValue(provider[0].enterpriseCode);
    this.form.get('nameProvider')?.setValue(provider[0].corporateName);
    this.inputProvider.value = provider[0].corporateName;
    this.form.get('postalCode')?.setValue(provider[0].address.zipCode);
    this.form.get('street')?.setValue(provider[0].address.street);
    this.form.get('state')?.setValue(provider[0].address.stateName);
    this.form.get('numberAddress')?.setValue(provider[0].address.number);
    this.form.get('neighborhood')?.setValue(provider[0].address.stateName);
    this.form.get('city')?.setValue(provider[0].address.city);
    this.form.get('operationalType')?.setValue(provider[0].operationalType);
    this.providerCompany = provider;
    this.providerPlaces = provider[0].places;
  }

  getProviderSearchOptions(event: any) {
    if (event.length > 0) {
      this.loading = true;
      this.fornecedoresService.getProviderOutsourcedByName(event).subscribe((res: any) => {
        this.providersList = res.content;
        this.getProviderList();
        this.inputProvider.changeOptionsFilter(this.providerOptionsList);
        this.inputProvider.optionsShow = true;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    } else {
      // this.loading = true;
      // this.fornecedoresService.getProviderOutsourcedByName('', 10, 0).subscribe((res: any) => {
      //   this.providersList = res;
      //   this.getProviderList();
      //   this.inputProvider.changeOptionsFilter(this.providerOptionsList);
      //   this.loading = false;
      //   this.inputProvider.optionsShow = true;
      // }, error => {
      //   this.loading = false;
      // });
    }
  }

  public getProviderList(): void {
    this.loading = true;
    this.providerOptionsList = [];
    this.providerOptionsList = this.providersList.map((item: any) => {
      let option = {
        name: item.corporateName, value: item.document
      }
      return option;
    });
    this.inputProvider.changeOptionsFilter(this.providerOptionsList);
    this.loading = false;
  }

  deleteFileActionPlan(type: string) {
    if (type === 'gpa') {
      this.archiveActionPlan = [];
      this.form.get('pathArchiveActionPlan')?.setValue('');
    }
    if (type === 'provider') {
      this.archiveActionPlanProvider = [];
      this.form.get('actionPlanProvider')?.setValue('');
    }
  }

  deleteFileDocument(type: string) {
    if (type === 'gpa') {
      this.archiveDocument = [];
      this.form.get('reportPaths')?.setValue('');
      this.form.get('reportFileName')?.setValue('');
    }
    if (type === 'provider') {
      this.archiveDocumentProvider = [];
      this.form.get('documentProvider')?.setValue('');
    }
  }

  openModalUploadDocument(type: string) {
    const dialogref = this.dialog.open(ModalFileComponent, {
      data: {
        selectFile: true,
        title: 'Carregar Documento'
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.uploadArchiveDocument = result.file;
        if (this.uploadArchiveDocument) {
          this.loading = true;
          this.auditoriaService.uploadFile(this.uploadArchiveDocument).subscribe(res => {
            this.loading = false;
            if (res) {
              this.showButtonDelete = true;
              this.form.get('reportPaths')?.setValue(res);
            }
          }, error => {
            this.loading = false;
            const hash64 = error.error.text;
            if (hash64) {
              this.showButtonDelete = true;
              if (type === 'gpa') {
                this.form.get('reportPaths')?.setValue(hash64);
                const fileName = atob(this.form.get('reportPaths')?.value);
                this.form.get('reportFileName')?.setValue(fileName);
                this.archiveDocument = [
                  {
                    name: fileName.slice(7),
                    path: hash64,
                  }
                ];
              }
              if (type === 'provider') {
                this.form.get('documentProvider')?.setValue(hash64);
                const fileName = atob(this.form.get('documentProvider')?.value);
                this.archiveDocumentProvider = [
                  {
                    name: fileName.slice(7),
                    path: hash64,
                  }
                ];
              }
            }
          });
        }
      }
    });
  }

  openModalUploadActionPlan(type: string) {
    const dialogref = this.dialog.open(ModalFileComponent, {
      data: {
        selectFile: true,
        title: 'Carregar Plano de Ação'
      },
    });
    dialogref.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.uploadArquiveActionPlan = result.file;
        if (this.uploadArquiveActionPlan) {
          this.loading = true;
          this.auditoriaService.uploadFile(this.uploadArquiveActionPlan).subscribe(res => {
            this.loading = false;
            if (res) {
              this.showButtonDelete = true;
              this.form.get('pathArchiveActionPlan')?.setValue(res);
            }
          }, error => {
            this.loading = false;
            if (error.error.text) {
              this.showButtonDelete = true;
              if (type === 'gpa') {
                this.form.get('pathArchiveActionPlan')?.setValue(error.error.text);
                this.archiveActionPlan = [
                  {
                    name: atob(error.error.text).slice(7),
                    path: error.error.text,
                  }
                ];
              }
              if (type === 'provider') {
                this.form.get('actionPlanProvider')?.setValue(error.error.text);
                this.archiveActionPlanProvider = [
                  {
                    name: atob(error.error.text).slice(7),
                    path: error.error.text,
                  }
                ];
              }
            }
          });
        }
      }
    });
  }

  downloadFile(type: string) {
    let file = '';
    let fileName = '';
    if (type === 'document') {
      file = this.form.get('reportPaths')?.value;
      fileName = atob(file);
    }

    if (type === 'document-provider') {
      file = this.form.get('documentProvider')?.value;
      fileName = atob(file);
    }

    if (type === 'action-plan') {
      file = this.form.get('pathArchiveActionPlan')?.value;
      fileName = atob(file);
    }

    if (type === 'action-plan-provider') {
      file = this.form.get('actionPlanProvider')?.value;
      fileName = atob(file);
    }
    if (file) {
      this.auditoriaService.getFile(file).subscribe((arquivo: Blob) => {
        const url = window.URL.createObjectURL(arquivo);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName.slice(7);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, error => {
      });
    }
  }
}
