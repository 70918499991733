import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isSidebarPinned = false
  isSafeGis: boolean = false;
  isCarrefour: boolean = false;
  isGpaUserProvider: boolean = false;
  isGpaUser: boolean = false;
  isSafeGisClient: boolean = true;
  user: any;
  storage!: Storage;
  isAdmin: boolean = false;
  isAdminGpa: boolean = false;

  needUpdateData: boolean = false;
  isAuditing: boolean = false;

  constructor(
    private appService: AppService,
    private router: Router,
  ) {
    this.storage = window.sessionStorage;
    this.user = this.storage.getItem('user');
    this.user = this.user ? JSON.parse(this.user) : {};

    if (this.user.authorities.some((item: string) => item === 'ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {

    const currentUrl = this.router.url;

    let user: any = sessionStorage.getItem('user');

    user = JSON.parse(user);

    if (user.authorities.some((item: string) => item === "ROLE_GPA_GEOMONITORING_ADMIN") && currentUrl.includes('safe-gis')) {
      this.isAdminGpa = true;
      this.isSafeGis = true;
    }

    if (user.authorities.some((item: string) => item === "ROLE_GEOMONITORING_CLIENT_LEGACY" || item === "ROLE_GEOMONITORING_CLIENT" || item === "ROLE_GEOMONITORING_ADMIN")) {
      this.isSafeGis = true;
      return
    }

    if (user.authorities.some((item: string) => item === "ROLE_QUALITY" || item === "ROLE_QUALITY_AUDITING")) {
      this.isAuditing = true;
    }

    if (user.authorities.some((item: string) => item === "ROLE_PROVIDER") || currentUrl.includes('minha-empresa')) {
      this.isGpaUserProvider = true;
    } else {
      this.isGpaUser = true;
    }

    if (user.authorities.some((item: string) => item.includes("CARREFOUR"))) {
      this.isCarrefour = true;
    } else {
      this.isCarrefour = false;
    }

    if (user.confirmPersonalData == true) {
      this.needUpdateData = true;
    } else {
      this.needUpdateData = false;
    }

  }

  checkAuthorities(menuType: string): boolean {
    return false;
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }

  toggleArrowSide() {
    if (!this.isSafeGis) {
      this.isSidebarPinned = !this.isSidebarPinned
      this.appService.toggleSidebarPin();
    }
  }

  redirectToGpa() {
    const url = environment.safeGisUrl;
    window.open(url, '_blank');
  }

  rotationArrow() {
    return this.isSidebarPinned ? 'rotate(-180deg)' : ''
  }
}
