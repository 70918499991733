import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSocialAuditComponent } from './form-social-audit.component';
import { FormSocialAuditRoutingModule } from './form-social-audit-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
  declarations: [
    FormSocialAuditComponent
  ],
  imports: [
    CommonModule,
    FormSocialAuditRoutingModule,
    ReactiveFormsModule,
    MatDialogModule,
    ComponentsModule,
  ],
  exports: [
    FormSocialAuditRoutingModule
  ]
})
export class FormSocialAuditModule { }
